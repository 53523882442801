<template>
  <div class="zone">
    <div class="indent">
      <div class="indentone">
        <div class="left">质控面板</div>
      </div>
      <div class="below">
        <!-- <div class="city">
          <el-select
            v-model="ordering"
            placeholder="排序"
            size="small"
            clearable
            @change="changeFun"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div> -->
        <div class="title">省份：</div>
        <div class="cityProvince">
          <el-select
            v-model="valueProvince"
            placeholder="请选择省份"
            size="small"
            filterable
            clearable
          >
            <el-option
              v-for="item in optionsProvince"
              :key="item.province_id"
              :label="item.province_name"
              :value="item.province_id"
            ></el-option>
          </el-select>
        </div>
        <div class="calendarone">
          <div class="title">时间：</div>
        </div>
        <timePickerCloud :pickerOptions="pickerOptions" :end_stats_dayOld="end_stats_day" :start_stats_dayOld="start_stats_day" @setTime="setTime"></timePickerCloud>
        <div class="seachone">
          <el-button type="primary" size="small" :disabled="btn_disabled" :loading="btn_disabled" @click="searchBtn">
            搜索
          </el-button>
        </div>
      </div>
    </div>
    <div
      class="content"
      v-infinite-scroll="loading"
      infinite-scroll-disabled="disabled"
    >
      <div class="faceplate" v-for="(item, index) in dataList" :key="index"  @click="toavocation(item)">
        <div class="faceplatetop">
          <div class="faceplatetop-left" @click="toavocation(item)">
            <img src="../../assets/img/location.png" alt="" />
            {{ item.province_name }}
          </div>
          <img
            src="../../assets/img/marshalling.png"
            alt=""
            class="faceplatetop-right" 
           
          />
        </div>
        <div class="faceplatetop1">
          <div class="faceplatetop-left1">
            <img src="../../assets/img/hospital .png" alt="" />
            医院(家)
          </div>
          <div class="faceplatetop-right1">{{ item.hospital_num }}</div>
        </div>
        <div class="faceplatetop1">
          <div class="faceplatetop-left1">
            <img src="../../assets/img/apparatus.png" alt="" />
            仪器(台)
          </div>
          <div class="faceplatetop-right1">{{ item.instrument_num }}</div>
        </div>
        <div class="faceplatetop2">
          <div class="faceplatetop-left1">
            <img src="@/assets/img/control2.png" alt="" />
            失控(次)
          </div>
          <div class="faceplatetop-right2">{{ item.runaway_count }}</div>
        </div>
      </div>
      <p v-if="loading">加载中...</p>
      <p v-if="noMore">没有更多了</p>
      <p v-if="!loading&&dataList.length==0">暂无数据</p>

    </div>
  </div>
</template>

<script>
import api from '@/api/quality'
import { getCity2 } from '@/api/search.js'
import timePickerCloud from '../../components/timePickerCloud.vue'

export default {
  name: 'Panel',
  components: {
    timePickerCloud,
  },
  data() {
    return {
      radio: '日', //时间节点
      start_stats_year: '', //开始年份
      end_stats_year: '', //结束年份
      start_stats_day: '', //开始日期
      end_stats_day: '', //结束日期
      dateMonthValue: ['2022-05-01', '2022-06-13'], //月份和日期选择
      pickerOptions: {}, //日期选择器特有的选项
      btn_disabled: false,

      options: [
        {
          value: 'hospital_num',
          label: '医院数量',
        },
        {
          value: 'instrument_num',
          label: '仪器数量',
        },
        {
          value: 'runaway_count',
          label: '失控次数',
        },
      ], //排序-下拉列表
      ordering: '', //排序-下拉列表-值

      optionsProvince: [], //省份-下拉列表
      valueProvince: '', //省份-下拉列表-值

      dataList: [], //列表数据
      page: 1,
      size: 100,
      count: 0,
      loading: false,
    }
  },

  computed: {
    type() {
      const arr = {
        年: 'year',
        月: 'monthrange',
        日: 'daterange',
      }
      return arr[this.radio]
    },
    startPlaceholder() {
      const arr = {
        年: '开始年份',
        月: '开始月份',
        日: '开始日期',
      }
      return arr[this.radio]
    },
    endPlaceholder() {
      const arr = {
        年: '结束年份',
        月: '结束月份',
        日: '结束日期',
      }
      return arr[this.radio]
    },
    format() {
      const arr = {
        年: 'yyyy',
        月: 'yyyy/MM',
        日: 'yyyy/MM/dd',
      }
      return arr[this.radio]
    },
    formatValue() {
      const arr = {
        年: 'yyyy',
        月: 'yyyy-MM',
        日: 'yyyy-MM-dd',
      }
      return arr[this.radio]
    },
    noMore() {
      return this.count >= 50
    },
    disabled() {
      return this.loading || this.noMore
    },
  },

  watch: {
    type() {
      this.start_stats_year = ''
      this.end_stats_year = ''
      this.dateMonthValue = []
    },
  },
  created() {
    let nowdate = new Date()
    // this.dateMonthValue[1] = ''+
    //   nowdate.getFullYear() +
    //   (nowdate.getMonth() + 1) +
    //   nowdate.getDate()
    let endyyyy = nowdate.getFullYear(),
        endMM = nowdate.getMonth() + 1,
        enddd = nowdate.getDate();
    nowdate.setDate(nowdate.getDate() - 30)
    let stryyyy = nowdate.getFullYear(),
        strMM = nowdate.getMonth() + 1,
        strdd = nowdate.getDate();
    // this.dateMonthValue[0] =   ''+
    //   nowdate.getFullYear() +
    //   (nowdate.getMonth() + 1) +
    //   nowdate.getDate()
    this.start_stats_day = `${stryyyy}${strMM<=9?'0'+strMM:strMM}${strdd<=9?'0'+strdd:strdd}`
    this.end_stats_year = `${endyyyy}${endMM<=9?'0'+endMM:endMM}${enddd<=9?'0'+enddd:enddd}`
  },
  mounted() {
    this.cityFun()
    this.gitqcpanel()
  },
  methods: {
    // 设置时间
    setTime(val) {
      this.start_stats_day = val.start_stats_day;
      this.end_stats_day = val.end_stats_day;
    },
    // 年份联动选择处理,开始年份>结束年份
    changeYearData() {
      if (
        this.end_stats_year != '' &&
        this.start_stats_year > this.end_stats_year
      ) {
        this.end_stats_year = '' //结束年份
      }
    },
    cityFun() {
      getCity2().then((res) => {
        this.optionsProvince = res.data
      })
    },

    // 排序监听
    changeFun(value) {
      this.dataList = []
      this.page = 1
      this.count = 0
      this.gitqcpanel()
    },

    // 获取列表
    gitqcpanel() {
      this.loading = true
      
      let param = {
        province_id: this.valueProvince, //省份标识
        begin_time: '', //开始时间
        end_time: '', //结束时间
        page: this.page,
        size: this.size,
        ordering: this.ordering,
      }
      if(this.start_stats_day&&this.end_stats_day){
        param.begin_time = this.start_stats_day.substring(0,4)+'-'+ this.start_stats_day.substring(4,6)+'-'+ this.start_stats_day.substring(6,8),
        param.end_time = this.end_stats_day.substring(0,4)+'-'+this.end_stats_day.substring(4,6)+'-'+this.end_stats_day.substring(6,8);
      }
      // if (this.radio == '年') {
      //   param.begin_time =
      //     this.start_stats_year != ''
      //       ? new Date(this.start_stats_year).getTime()
      //       : ''
      //   param.end_time =
      //     this.end_stats_year != ''
      //       ? new Date(this.end_stats_year).getTime()
      //       : ''
      // } else if (this.radio == '月' || this.radio == '日') {
      //   param.begin_time =
      //     this.dateMonthValue.length > 0
      //       ? new Date(this.dateMonthValue[0]).getTime()
      //       : ''
      //   param.end_time =
      //     this.dateMonthValue.length > 0
      //       ? new Date(this.dateMonthValue[1]).getTime()
      //       : ''
      // }
      this.btn_disabled = true;
      api
        .qcpanel(param)
        .then((res) => {
          this.dataList = this.dataList.concat(res.data.data)
          this.page++
          this.count = res.data.count
          this.loading = false
        })
        .catch((err) => {
          this.loading = false
        }).finally(msg => {
          this.btn_disabled = false;
        })
    },

    // 搜索
    searchBtn() {
      this.page = 1
      this.dataList = []
      this.gitqcpanel()
    },

    // 跳转-质控面板（医院端跳转，奥普端/代理商不跳转）-医院列表
    toavocation(item) {
      let query = {
        province_id: item.province,
        province_name: item.province_name,
        radio: this.radio,
        start_stats_day: this.start_stats_day,
        end_stats_day: this.end_stats_day,
        dateMonthValue: this.dateMonthValue,
      }
      this.$router.push({ path: '/Panel', query })
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .el-date-editor .el-range__icon {
  display: none;
}

.indent {
  width: px(1630);
  height: px(107);
  background: #ffffff;
  box-shadow: 0 px(2) px(10) 0 rgba(51, 64, 79, 0.1);
  border-radius: px(5);
  margin-bottom: px(20);
  padding: px(15);
}
.indentone {
  width: 100%;
  height: px(56);
  display: flex;
}
.left {
  justify-content: flex-start;
  display: flex;
  align-items: center;
  font-size: px(20);
  font-weight: bold;
  color: #111111;
}

.right {
  flex: 1;
  justify-content: flex-end;
  display: flex;
  align-items: center;
}
.below {
  height: px(36);
  display: flex;
}

.calendar {
  margin-left: px(10);
}
.calendarDate {
  ::v-deep .el-input,
  ::v-deep .el-input__inner {
    width: 100%;
  }
}

.cityProvince {
  margin-left: px(10);
}
::v-deep .cityProvince .el-input--small .el-input__inner {
  width: px(220);
}

.calendarone {
  display: flex;
  margin-left: px(10);
}
.seachone {
  margin-left: px(10);
}
.content {
  width: 100%;
  display: flex;
  flex-shrink: 1;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  font-size: px(18);
}
.faceplate {
  width: px(348);
  height: px(219);
  background: #ffffff;
  border-radius: px(5);
  padding: 0 px(15);
  margin: 0 px(20) px(20) 0;
}
.faceplate:nth-child(4n) {
  margin: 0 0 px(20) 0;
}
.faceplatetop {
  color: #333333;
  line-height: px(50);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.faceplatetop1 {
  height: px(52);
  color: #333333;
  line-height: px(52);
  border-bottom: px(1) solid #d0d7e0;
  display: flex;
}
.faceplatetop2 {
  height: px(52);
  color: #333333;
  line-height: px(52);
  display: flex;
}

.interface {
  width: px(234);
  height: px(50);
  display: flex;
}
.interfaceleft {
  flex: 1;
}

.faceplatetop-left1 img {
  width: px(16);
  height: px(16);
  margin-right: px(5);
}

.faceplatetop-right1 img {
  width: px(13);
  height: px(16);
}
.interfaceleft-top {
  font-size: px(14);
  color: #666666;
}
.interfaceright-top {
  font-size: px(14);
  color: #666666;
}
.interfaceleft-bottom {
  font-size: px(20);
  font-weight: bold;
  color: #203152;
  margin-left: px(14);
}
.interfaceright-bottom {
  font-size: px(20);
  font-weight: bold;
  color: #f1734e;
  margin-left: px(14);
}
.faceplatetop-left {
  justify-content: flex-start;
  display: flex;
  align-items: center;
  cursor: pointer;
  img {
    width: px(15);
    height: px(17);
    margin-right: px(5);
  }
}
.faceplatetop-left1 {
  justify-content: flex-start;
  display: flex;
  align-items: center;
}
.faceplatetop-right {
  width: px(15);
  height: px(15);
  cursor: pointer;
}
.faceplatetop-right1 {
  flex: 1;
  justify-content: flex-end;
  display: flex;
  align-items: center;
  font-size: px(20);
  font-weight: bold;
  color: #203152;
}
.faceplatetop-right2 {
  flex: 1;
  justify-content: flex-end;
  display: flex;
  align-items: center;
  font-size: px(20);
  font-weight: bold;

  color: #f1734e;
}
</style>
